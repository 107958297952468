import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

const socket = io('http://localhost:3005');

function App() {
  const [tweets, setTweets] = useState([]);
  const [newTweet, setNewTweet] = useState('');

  useEffect(() => {
    socket.on('tweet', (tweet) => {
      setTweets((prevTweets) => [tweet, ...prevTweets]);
    });
  }, []);

  const handleTweetSubmit = () => {
    if (newTweet.trim() !== '') {
      const tweet = {
        content: newTweet,
        timestamp: new Date().toLocaleString(),
      };
      socket.emit('tweet', tweet);
      setNewTweet('');
    }
  };

  return (
    <div>
      <h1>Twitter風掲示板</h1>
      <textarea
        rows="4"
        cols="50"
        placeholder="つぶやきを入力してください"
        value={newTweet}
        onChange={(e) => setNewTweet(e.target.value)}
      />
      <button onClick={handleTweetSubmit}>投稿する</button>

      <div>
        <h2>投稿一覧</h2>
        {tweets.map((tweet, index) => (
          <div key={index}>
            <p>投稿内容: {tweet.content}</p>
            <p>投稿日時: {tweet.timestamp}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
